import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../../home/home";
import OfferDetail from "../../offers/OfferDetail";
import { useContext, useEffect, useState } from "react";
import { isnofferwallapi } from "../../../config/axios/axiosconfig";
import { OffersContext } from "../../../context/offers/OffersContext";
import GiftCatalogue from "../../giftcards/GiftCatalogue";
import GiftCard from "../../giftcards/GiftCard";
import GiftCardStat from "../../giftcards/GiftCardStat";
import FuelCard from "../../fuel/FuelCard";
import Header from "../../../components/masterlayout/Header";
import Offers from "../../offers/Offers";
import { ClientContext } from "../../../context/client/ClientContext"
//About
import TermsAndConditions from "../../terms/index";
import FAQS from "../../faq/faqs";
import HowItWorks from "../../howitworks/howItWorks";
import FooterSection from "../../../components/masterlayout/Footer";
import Cashback from "../../profile/cashback";
import Account from "../../profile/account";
import Payment from "../../payment/payment";
import Loader from "../../../components/skeleton/Loader";
import GiveAway from "../../giveaway/GiveAway";
import OffersSearch from "../../offers/OffersSearch";
import ForgotPassword from "../../auth/ForgotPassword";
import ForgotPasswordVerification from "../../auth/ForgotPasswordVerification";
import ContactUs from "../../contactus/ContactUs";
import ReferAFriend from "../../referAFriend/referAFriend";
import { getFavourites } from "../../../utils/favourites";
import { FavouritesContext } from "../../../context/favourites/FavouritesContext";
import { setFavourites } from "../../../utils/offers";
import Brands from "../../brands/Brands";
import MyFavourites from "../../profile/MyFavourites";
import Policy from "../../terms/privacy-policy/policy";
import AboutUs from "../../aboutus/AboutUs";
import RewardCard from "../../rewardredeem/RewardCard";
import RewardCardStat from "../../rewardredeem/RewardCardStat";
import Withdrawal from "../../profile/withdrawal";
import GiveAway2 from "../../giveaway/GiveAway2";

export default function ISNLayout() {

    const { client, clientdispatch } = useContext(ClientContext);

    const [height, setHeight] = useState("145px");

    const { offers, offersdispatch } = useContext(OffersContext);
    const { favourites, favouritesdispatch } = useContext(FavouritesContext);

    const [loader, setLoader] = useState(false);
    const [existingBrands, setExistingBrands] = useState([]);
    const { pathname } = useLocation();

    const getFavs = async (userSession) => {
        if (userSession) {
            try {


                favouritesdispatch({ type: "FAVOURITES_START" });
                const res = await getFavourites(userSession?.euid)
                //  await isnofferwallapi.get("/api/favourite-offers", {
                //     params: {
                //         euid: client?.euid
                //     },

                // });

                favouritesdispatch({ type: "FAVOURITES_SUCCESS", payload: res });
                return res;

            } catch (error) {
                console.log("FAV ERROR", error)
                favouritesdispatch({ type: "FAVOURITES_FAIL", payload: error });
            }
        }
    }
    const getOffers = async (userSession) => {

        setLoader(true);
        try {
            const startLoad = new Date();
            console.log("Load Start", startLoad);
    
            const startFavouriteRequest = new Date();
            console.log("Favourite Request Start", startFavouriteRequest);

            const myfavs = await getFavs(userSession);

            const endFavouriteRequest = new Date();
            console.log("Favourite Request End", endFavouriteRequest);
            console.log("Favourite Request Time", endFavouriteRequest - startFavouriteRequest);

            offersdispatch({ type: "OFFERS_START" });

            const startOfferRequest = new Date();
            console.log("Offer Request Start", startOfferRequest);

            const res = await isnofferwallapi.get("/api/offers/offersgetlite", {
                params: {
                    languagecode: "en",
                }
            });

            const endOfferRequest = new Date();
            console.log("Offer Request End", endOfferRequest);
            console.log("Offer Request Time", endOfferRequest - startOfferRequest);



            const startOfferDataset = new Date();
            console.log("Offer Dataset Start", startOfferDataset);

            const offersOrganized = setFavourites(res.data, myfavs);

            const endOfferDataset = new Date();
            console.log("Offer Dataset End", endOfferDataset);
            console.log("Offer Dataset Time", endOfferDataset - startOfferDataset);

            const endLoad = new Date();
            console.log("Load End", endLoad);
            console.log("Load Time", endLoad - startLoad);

            offersdispatch({ type: "OFFERS_SUCCESS", payload: offersOrganized });
            setLoader(false);
        } catch (error) {
            offersdispatch({ type: "OFFERS_FAIL", payload: error });
            setLoader(false);
        }
    }
    useEffect(() => {
        clientdispatch({ type: "CLIENT_START" });

        const userSessionCookie = document.cookie
            .split("; ")
            .find((row) => row.startsWith("userSession"))
            ?.split("=")[1];


        const userSession = userSessionCookie
            ? JSON.parse(decodeURIComponent(userSessionCookie))
            : null;


        clientdispatch({ type: "CLIENT_SUCCESS", payload: userSession });

      
        getOffers(userSession);
       
    }, []);

    useEffect(() => {
        if (client) {
            if (client?.subscription_status === "active") {
                setHeight("85px");
            } else {
                setHeight("145px");
            }
            //getOffers();
        } else {
            //if(!offers) getOffers()
            setHeight("85px");
        }
        // getOffers();

    }, [client]);

    useEffect(() => {
        const page = document.getElementById("divPage");
        const footer = document.getElementById("divFooter");
        if (page && footer) {
            const footerHeight = footer.offsetHeight;
            const offsetTop = footer.offsetTop;
            const windowHeight = window.innerHeight;
            if (offsetTop < windowHeight - footerHeight) {
                //alert("short")
                page.style.minHeight = (windowHeight - footerHeight - page.offsetTop - 45) + "px"
            } else {
                // alert("long")

            }
        }
    }, [pathname]);

    return <div>
        <header>
            <Header />
        </header>

        <div id="divPage" className="" style={{ marginTop: height }}>
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"/offers/:type/detail/:id"} element={<OfferDetail />} />
                <Route path={"/offers/:type"} element={<Offers />} />

                <Route path={"/brands"} element={<Brands />} />
                <Route path={"/brands/:brandname"} element={<Offers />} />

                <Route path={"/categories/:category"} element={<Offers />} />
                <Route path={"/categories/:category/detail/:id"} element={<OfferDetail />} />



                 {/* <Route path={"/giftcards"} element={<GiftCatalogue />} />
                <Route path={"/giftcards/giftcard/:code"} element={<GiftCard />} />  */}
                <Route path={"/payments/callback/*"} element={<GiftCardStat />} />
                <Route path={"/payments/load"} element={<Payment />} />

                <Route path={"/fuel/fuelcard"} element={<FuelCard />} />

                <Route path={"/giveaways"} element={<GiveAway2 />} />
                <Route path={"/giveaways/:month"} element={<GiveAway2 />} />
                <Route path={"/giveaways/:month/detail/:id"} element={<OfferDetail />} />

                <Route path={"/search/:searchparam"} element={<OffersSearch />} />
                <Route path={"/search/:searchparam/detail/:id"} element={<OfferDetail />} />

                <Route path={"/myfavourites"} element={<MyFavourites />} />
                <Route path={"/myfavourites/:favid"} element={<OfferDetail />} />

                <Route path={"/about/terms-and-conditions"} element={<TermsAndConditions />} />
                <Route path={"/about/faqs"} element={<FAQS />} />
                <Route path={"/about/how-it-works"} element={<HowItWorks />} />
                <Route path={"/about/contact_us"} element={<ContactUs />} />
                <Route path={"/about/privacy-policy"} element={<Policy />} />
                <Route path={"/about/aboutus"} element={<AboutUs />} />
                <Route path={"/about/aboutus/:previouswinners"} exact element={<Offers />} />
                <Route path={"/about/aboutus/:previouswinners/:aboutus"} element={<OfferDetail />} />
                <Route path={"/about/aboutus/:aboutus"} element={<OfferDetail />} />


                <Route path={"/user/account"} element={<Account />} />
                <Route path={"/user/cashback"} element={<Cashback />} />
                <Route path={"/user/forgot_pasword"} element={<ForgotPassword />} />
                <Route path={"/user/forgot_pasword_verification"} element={<ForgotPasswordVerification />} />
                <Route path={"/user/refer_a_friend"} element={<ReferAFriend />} />
                <Route path={"/reward/redeem"} element={<RewardCard />} />
                <Route path={"/reward/redeem/status"} element={<RewardCardStat />} />
                <Route path={"/user/withdraw"} element={<Withdrawal />} />
            </Routes>
        </div>

        <footer id="divFooter">
            <FooterSection />
        </footer>
        {/* <Loader loader={loader} /> */}
    </div>;
}